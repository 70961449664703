import styles from './index.module.css';
import Config from '../../config';
function Dialog({ message, onConfirm }) {
  const con_style = {
    backgroundImage:`url(${Config.ossDoMain}/images/error_bg.webp)`,
    backgroundSize:'contain',
    backgroundRepeat:'no-repeat'
  }
  return (
    <div className={styles.dialog}>
      <div className={styles.mask}></div>
      <div className={styles.con} style={con_style}>
        <div className={styles.text} dangerouslySetInnerHTML={{__html:message}}></div>
        <div className={styles.h77}></div>
        <div className={styles.btn_wrap} onClick={onConfirm}>
          <div className={styles.con}>
          <img src={`${Config.ossDoMain}/images/t_queren.png`} alt="确认"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dialog;
