import Util from "../utils";
function getQrCode(params) {
    const urlParams = Util.objectToUrlParams(params);
    const pStr = urlParams.length>0?`?${urlParams}`:''
    return new Promise(function(resolve,reject){
        fetch(`/user/getQrcode${pStr}`)
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if(data.isSuccess){
                resolve(data.data)
            }else{
                reject(data.errMsg) 
            }
        })
        .catch(error => reject(error));
    })
}
export default getQrCode;
