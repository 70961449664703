import { useEffect, useState } from 'react'
import Config from '../../config';
import styles from './index.module.css';
function Blessing({type,initialPosition,zIndex,children}) {
    let _left = initialPosition[0]
    let direction = 'left'
    let _step = direction==='left'?-1:1;
    const [left, setLeft] = useState(initialPosition[0])
    const [style,setStyle] = useState({
        left: initialPosition[0]+'px',
        top: initialPosition[1]+'px',
    })

    const mapBg = {
        '1':'1',
        '2':'3',
        '3':'3',
        '4':'2',
        '5':'3',
        '6':'1',
        '7':'3',
        '8':'1',
        '9':'3',
        '10':'1',
        '11':'1',
        '12':'3',
        '13':'2',
        '14':'1',
        '15':'1',
        '16':'3',
        '17':'2',
        '18':'3'
    }
    
    function animate() {
        _left = _left + _step
        // 继续下一次动画
        let bs = initialPosition[0]>1290?2*1290:1290
        let ad = initialPosition[0]>1290?0:1290
        if(_left<initialPosition[0]-bs){
            _left = ad+initialPosition[0]
        }
        setStyle({
            left: _left+'px',
            top: initialPosition[1]+'px',
            zIndex:zIndex
        })
       requestAnimationFrame(animate);
      }
      
    
      
    useEffect(()=>{
      // 启动动画
     requestAnimationFrame(animate);
    },[])
    return (
      <div className={styles.item_wrap} type={type} style={style}>
        <span>{children}</span>
        <img className={styles.img} src={`${Config.ossDoMain}/images/v_bg_${mapBg[type]}.webp`} alt=''/>
      </div>
    );
  }
  
  export default Blessing;