import CloseBtn from '../close_btn';
import Config from '../../config';
import styles from './index.module.css';
function StageDetail({visible,src,onClick}) {
    return (
        <>
        {visible &&
        <div className={styles.notice}>
            <div className={styles.mask}></div>
            <div className={styles.con}>
                <img className={styles.pic} src={Config.ossDoMain+src} alt=""/>
                <div className={styles.h77}></div>
                <div className={styles.c_box} onClick={onClick}><CloseBtn></CloseBtn></div>
            </div>
        </div>
        }
        </>
    );
  }
  
  export default StageDetail;
  