import CloseBtn from '../close_btn';
import styles from './index.module.css';
import Config from '../../config';
function Rule({visible,onClick}) {
    const con_style = {
        backgroundImage:`url(${Config.ossDoMain}/images/rule_bg.webp)`,
        backgroundSize:'contain',
        backgroundRepeat:'no-repeat'
    }
    return (
        <>
        {visible &&    
        <div className={styles.rule}>
            <div className={styles.mask}></div>
            <div className={styles.con} style={con_style}>
               <div className={styles.scroll}>
                <img src={`${Config.ossDoMain}/images/rule_text.webp`} alt=""/>
               </div>
            </div>
            <div className={styles.close} onClick={onClick}>
                <CloseBtn></CloseBtn>
            </div>
        </div>
        }
        </> 
    );
  }
  
  export default Rule;
  