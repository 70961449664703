
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';

import Config from "../../config";
import Progress from '../../components/progress';
import Stage from '../../components/stage';
import TextArea from '../../components/text_area';
import QuickTags from '../../components/quick_tags';
import NavCard from '../../components/nav_card';
import StageDetail from '../../components/stage_detail';

import sendGreeting from "../../request/sendGreeting";
import getGreetingCount from "../../request/getGreetingCount";
import Util from '../../utils';
function Send() {

    const [tags] = useState([
        "美国运通生日快乐",
        "美国运通,廿廿相伴",
        "20岁生日快乐",
        "祝你生日快乐",
        "美国运通20周年生日快乐"
    ])
    const [textValue, setTextValue] = useState('')
    const [blessingCount, setBlessingCount] = useState(0)
    const [visibleStageDetail, setVisibleStageDetail] = useState(false)
    const [src, setSrc] = useState('')
    let navigate = useNavigate();

    const onSendMessage = useCallback((value) => {
        // 过滤敏感词
       let res = Util.detection(value)
       console.log("敏感词过滤：",res)
       if(!res.result){
        window._alert(`非法输入`)
         return
       }
       const params = {
        msg: value||'', 
        channelCode: Util.getChannelCode()
       }
       if(window.AMEX_ALLPARAMS.referrerUserId){
        params['shareUid'] = window.AMEX_ALLPARAMS.referrerUserId
       }
       sendGreeting(params).then(res=>{
        if(res.bindPrizeCards && res.bindPrizeCards.length>0){
            window._alert(`感谢绑定运通卡<br/>额外加赠${res.bindPrizeCards.length}张抽奖卡！`,function(){
                navigate(`/task?lotNo=${res.prizeCard||''}&blessing=${encodeURIComponent(value)}`);
            })
        }else{
            navigate(`/task?lotNo=${res.prizeCard||''}&blessing=${encodeURIComponent(value)}`);
        }
       }).catch(err=>{
        window._alert(err,function(){
            navigate(`/task?lotNo=&blessing=`);
        })
       })
       
    },[navigate])
    
    const onGetTag = useCallback((e) => {
        if(!e) return
        setTextValue(e)
    },[])
    const onShowCard = useCallback(() => {
        navigate('/card');
    },[])


    const onShowStageDetail = (e) => {
        console.log(e)
        setSrc(`/images/s_d_${e+1}.webp`)
        setVisibleStageDetail(true)
    }
    const onHideStageDetail = () => {
        setVisibleStageDetail(false)
        setSrc('')
    }

    const onGreetingCount = () => {
        getGreetingCount().then(res=>{
            setBlessingCount(res)
        }).catch(err=>{
            window._alert(err)
        })
    }
    useEffect(()=>{
       onGreetingCount()
    },[])
    return (
      <>
      <div className="Send page_container">
        <div className="s_container">
            <img className="s_title" src={`${Config.ossDoMain}/images/s_title.webp`} alt=""/>
            <div className="h60"></div>
            <TextArea onSendMessage={onSendMessage} defaultValue={textValue}></TextArea>
            <div className="tags_area">
                <h3>快捷输入：</h3>
                <div className="tags_wrap">
                {tags.map(item => 
                <QuickTags key={item} onClick={()=>{onGetTag(item)}}>{item}</QuickTags>
                )}
                </div>
            </div>
            <div className="h40"></div>
            <div className="blessing_count">
                <div className="t">累计生日祝福</div>
                <div className="c"><span>{blessingCount}</span>条</div>
            </div>
            <Progress current={blessingCount}></Progress>
            <Stage current={blessingCount} onClick={onShowStageDetail}></Stage>
        </div>
      </div>
      <NavCard onClick={onShowCard}></NavCard>
      <StageDetail visible={visibleStageDetail} src={src} onClick={onHideStageDetail}></StageDetail>
      </>
    )
}
export default Send;