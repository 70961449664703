import {useCallback, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";

import CardList from '../../components/card_list';
import MyCard from '../../components/my_card';
import Notice from '../../components/notice';
import BlessingBox from '../../components/blessing_box';
import EditInfo from '../../components/edit_info';

import Util from '../../utils';
import Config from '../../config';

import getGreetingCount from "../../request/getGreetingCount";
import getMyPrizeCardCount from "../../request/getMyPrizeCardCount";
import getQrCode from "../../request/getQrCode";
import upload from "../../request/upload";
function Card() {

  const [visible, setVisible] = useState(false)
  const [visibleNotice, setVisibleNotice] = useState(false)
  const [noticeSrc, setNoticeSrc] = useState('')
  const [winNo, setWinNo] = useState('')
  const [listData, setListData] = useState([])
  const [blessingCount, setBlessingCount] = useState(0)
  const [tInfo, setTInfo] = useState({
     t:3,
     s:'一'
  })
  const [visibleEditInfo, setVisibleEditInfo] = useState(false)
  const [cardList, setCardList] = useState([])
  const [qrcode, setQrcode] = useState('')
  const [prizes, setPrizes] = useState({})
  const [infoLevel, setInfoLevel] = useState(1)
  const [curWinIndex, setCurWinIndex] = useState(0)
  
  

  let navigate = useNavigate();
  
  const onShowCard = (index) => {
    // setVisible(true)
    getListData(index)
  }

  const onHideNotice = () => {
    setVisibleNotice(false)
    console.log("curWinIndex",curWinIndex)
    if(cardList[curWinIndex].list.length>0){
      onShowCard(curWinIndex)
    }
  }

  // 支付宝需上传换取url地址
  const onAlipayUpload = async (id,data) => {
    const param = {
      id,
      imgData:data,
    }
    return new Promise(function(resolve){
      upload(param).then(res=>{
        console.log("upload:",res)
        const _url = Config.origin+res
        resolve(_url)
      }).catch(err=>{
        console.log(err)
        window._alert(err)
      })
    })
  }

  const drawCard = async (lotNo,blessing) => {
    const random = Math.floor(Math.random()*5)+1
    let param = {
      with:1018,
      height: 1426,
      bg: `/images/p_bg.webp`,
      title:`/images/p_save_title_1.webp`,
      // cake:`/images/p_cake_${random}.png`,
      card:`/images/p_card_${random}.webp`,
      text:`/images/p_save_text_1.webp`,
      mask:`/images/p_mask.png`,
      qrcode:qrcode,
      s_num:lotNo?`抽奖卡号：${lotNo}`:'',
      blessing:blessing,
    }
    const _data = await Util.poster(param)
    return !_data?'':_data
  }
  const drawPrizeCard = async (lotNo,blessing,level) => {
    // const random = Math.floor(Math.random()*5)+1
    let param = {
      with:1018,
      height: 1426,
      bg:`/images/p_bg.webp`,
      title:`/images/p_save_title_2.webp`,
      // cake:`/images/p_cake_${random}.png`,
      text:`/images/p_save_text_2.webp`,
      prizePic:`/images/p_prize_pic_${level}.webp`,
      mask:`/images/p_mask.png`,
      qrcode:qrcode,
      s_num:lotNo?`抽奖卡号：${lotNo}`:'',
      blessing:blessing,
    }
    const _data = await Util.prizePoster(param)
    return !_data?'':_data
  }

  // 获取中间结果数据
  // status 0 未中奖，1 中奖
  const getListData = async (index) => {
    console.log("抽奖卡列表",cardList,index)
    let pc_data = cardList[index].list
    console.log("pc_data",pc_data,pc_data.length<1)
    if(pc_data.length<1) return

    let openStatus = cardList[index].status
    let data = []
    for(let item of pc_data){
      let src = ''
      let url = ''
      console.log("item",item)
      if(item.cardNo && localStorage.getItem(item.cardNo)){
        src = localStorage.getItem(item.cardNo)
        console.log("海报图走的缓存")
        if(Util.getChannelCode()==='ALIPAY' && item.cardNo && localStorage.getItem(item.cardNo+'_url')){
          url = localStorage.getItem(item.cardNo+'_url')
        }
      }else{
         // 支付宝需上传换取url地址
        if(Util.getChannelCode()==='ALIPAY' && item.sharePicPath){
          src = Config.origin+item.sharePicPath
          url = Config.origin+item.sharePicPath
        }else{
          src = item.status ? await drawPrizeCard(item.cardNo, item.msg||'',item.prizeType) : await drawCard(item.cardNo, item.msg||'');
        }
        console.log("海报图走的生成")
        // 支付宝需上传换取url地址
        if(Util.getChannelCode()==='ALIPAY' && !url){
         url = await onAlipayUpload(item.id, src)
        }
      }
      data.push({
        src:src,
        url:url,
        status: openStatus?(item.status?(item.mobile?2:1):0):-1,
        hasInfo: (item.name && item.address)? true : false,
        level: item.level
      })
      // 实在海报图缓存
      try{
        item.cardNo && src && localStorage.setItem(item.cardNo,src)
        item.cardNo && url && localStorage.setItem(item.cardNo+'_url',url)
      }catch(err){
        console.log(err)
      }
    }
    console.log('listData::')
    console.log(data)
    setListData(data)
    setVisible(true)
  }

  const onGreetingCount = useCallback(() => {
    getGreetingCount().then(res=>{
      setBlessingCount(res)
      const _res = Util.getTextInfo(res)
      setTInfo(_res)
    }).catch(err=>{
      window._alert(err)
    })
  },[])
  // 关闭我的卡券面板
  const onCloseMyCard = useCallback((e) => {
    setVisible(false)
    if(e && typeof e==="string"){
      window._alert(e) 
    }
  },[])

  // 前往送祝福
  const onGoSend = useCallback(() => {
    setVisible(false)
    navigate('/send')
  },[])
  // 前往填写信息
  const onGoInfo = useCallback((level) => {
    //navigate('/info')
    console.log("level:",level)
    setInfoLevel(level)
    setVisibleEditInfo(true)
    onCloseMyCard()
  },[onCloseMyCard])

  // 提交信息之后的回调
  const onCallBack = () => {
    setVisibleEditInfo(false)
  }

  // 获取中奖信息
  const onMyPrizeCardCount = useCallback(() => {
    const param = {}
    const _data =[{
      level: 1,
      src:'/images/card_poster_1.webp',
      count:0,
      text:'/images/t_e_1.png',
      // status:Util.getCompleteStats(blessingCount,1),
      status:0,
      list:[]
  },{
      level: 2,
      src:'/images/card_poster_2.webp',
      count:0,
      text:'/images/t_e_2.png',
      // status:Util.getCompleteStats(blessingCount,2),
      status:0,
      list:[]
  },{
      level: 3,
      src:'/images/card_poster_3.webp',
      count:0,
      text:'/images/t_e_3.png',
      // status:Util.getCompleteStats(blessingCount,3),
      status:0,
      list:[]
  },{
      level: 4,
      src:'/images/card_poster_4.webp',
      count:0,
      text:'/images/t_e_4.png',
      // status:Util.getCompleteStats(blessingCount,4),
      status:0,
      list:[]
  }]
    getMyPrizeCardCount(param).then(res=>{
      const {prizeCards,prizes} = res
      console.log("prizeCards",prizeCards)
      const openLevel = Object.keys(prizes)
      console.log("openLevel",openLevel)
      const _res = containData(_data, prizeCards, openLevel)
      console.log("合成的数据",_res)
      setCardList(_res)
      setPrizes(prizes)
    }).catch(err=>{
      window._alert(err)
    })
  },[])

  // 合并 count 数据
  function containData (_data, prizeCards, openLevel) {
    for(let item1 of _data){
      for(let key in prizeCards){
        console.log(key,prizeCards[key])
        console.log(item1.level ,key)
        if(item1.level === parseInt(key)){
          item1.count = prizeCards[key].length
          item1.list = prizeCards[key]
          item1.status = openLevel.includes(key)?1:0 // 是否开奖
        }
      }
      if(openLevel.includes(item1.level+'')){
        item1.status = 1
      }
    }
    return _data
  }

  // 显示中奖接口
  const onShowPrizeResult = useCallback((e) => {
      console.log("EEEEE",e,prizes, prizes[e])
     const curPrize = prizes[e]?prizes[e][0]:null
     if(!curPrize) return
    setWinNo(curPrize.cardNo)
    setNoticeSrc(`/images/noc_${e}.webp`)
    setVisibleNotice(true)
    setCurWinIndex(parseInt(e)-1)
  },[prizes])

  let retryCount = 0
  const onQrCode = useCallback(() => {
    const param = {
      channelCode:Util.getChannelCode()
    }
    const _url = localStorage.getItem(`${param.channelCode}_qrcode_url`)
    if(_url){
      setQrcode(_url)
      return
    }
    getQrCode(param).then(res=>{
      console.log("getQrCode:",res)
      setQrcode(res)
      try{
        localStorage.setItem(`${param.channelCode}_qrcode_url`,res)
      }catch(err){

      }
    }).catch(err=>{
      if(retryCount<2){
        retryCount++;
        onQrCode()
      }else{
        setQrcode(`/images/${param.channelCode}_qrcode.webp`)
      }
      console.log(err)
    })
  },[])

  useEffect(()=>{
    onQrCode()
  },[onQrCode])
  useEffect(()=>{
    onGreetingCount()
  },[onGreetingCount])
  useEffect(()=>{
   onMyPrizeCardCount()
  },[onMyPrizeCardCount])
  return (
    <>
    <div className="Card page_container">
      <div className="c_container">
          <div className="blessing_count">
              <div className="t">累计生日祝福</div>
              <div className="c"><span>{blessingCount}</span>条</div>
              <div className="d">祝福达<span>{tInfo.t}万</span>，将开启<strong>第{tInfo.s}轮抽奖</strong></div>
          </div>
          <div className="tags_wall_wrap">
          <BlessingBox></BlessingBox>
          </div>
          <CardList cardList={cardList} onShowCard={onShowCard} onShowPrizeResult={onShowPrizeResult}></CardList>
      </div>
      {visible && <MyCard listData={listData} onGoInfo={onGoInfo} onGoSend={onGoSend} onClose={onCloseMyCard}></MyCard>}
    </div>
    <Notice visible={visibleNotice} src={noticeSrc} str={winNo} onClick={onHideNotice}></Notice>
    <EditInfo visible={visibleEditInfo} level={infoLevel} onCallBack={onCallBack}></EditInfo>
    </>
  )
}
export default Card;