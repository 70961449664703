import styles from './index.module.css';
function BtnMax({onClick,children}) {
    return (
      <div className={styles.btn_max} onClick={onClick}>
        <div className={styles.btn_wrap}>
        {children}
        </div>
      </div>
    );
  }
  
  export default BtnMax;