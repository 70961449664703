import sensitive from "../sensitive"
const LEVEL1=30000;
const LEVEL2=70000;
const LEVEL3=200000;
const LEVEL4=370000;
function loadImage(src) {
    return new Promise(function (resolve, reject) {
        let img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = function(){
            resolve(img)
        }
        img.src = src
    })
}
async function qrcode(param){
    const canvas = document.createElement('canvas');
    canvas.width = 210
    canvas.height = 210
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    console.log('合成 qrcode:',param.qrcode)
    if(param.qrcode){
    const _qrcode = await loadImage(param.qrcode)
    if(getChannelCode()==='WECHAT'){
        ctx.drawImage(_qrcode, 0, 0, _qrcode.width, _qrcode.height, 10, 10,canvas.width-20,canvas.height-20);
    }
    if(getChannelCode()==='ALIPAY'){
        ctx.drawImage(_qrcode, 34, 34, 228, 228, 10, 10,canvas.width-20,canvas.height-20);
    }
    
    }
    const _mask = await loadImage(param.mask)
    ctx.globalCompositeOperation = 'destination-in'
    ctx.drawImage(_mask, 0, 0, _mask.width, _mask.height, 0, 0,canvas.width,canvas.height);
    return canvas
}

const cacheImgs = {}
async function poster(param){
    const canvas = document.createElement('canvas');
    canvas.width = param.with
    canvas.height = param.height
    const ctx = canvas.getContext('2d')
    const _bg = cacheImgs[param.bg]?cacheImgs[param.bg]:await loadImage(param.bg)
    if(!cacheImgs[param.bg]){
        cacheImgs[param.bg] = _bg
    }
    ctx.drawImage(_bg, 0, 0);
    const _title = cacheImgs[param.title]?cacheImgs[param.title]:await loadImage(param.title)
    if(!cacheImgs[param.title]){
        cacheImgs[param.title] = _title
    }
    ctx.drawImage(_title, 0, 0, _title.width, _title.height, 0, 182, _title.width, _title.height);
    // const _cake = cacheImgs[param.cake]?cacheImgs[param.cake]:await loadImage(param.cake)
    // if(!cacheImgs[param.cake]){
    //     cacheImgs[param.cake] = _cake
    // }
    // ctx.drawImage(_cake, 0, 0, _cake.width, _cake.height, 171, 469, _cake.width, _cake.height);
    const _card = cacheImgs[param.card]?cacheImgs[param.card]:await loadImage(param.card)
    if(!cacheImgs[param.card]){
        cacheImgs[param.card] = _card
    }
    ctx.drawImage(_card, 0, 0, _card.width, _card.height, 216, 467, 596, 381);
    const _text = cacheImgs[param.text]?cacheImgs[param.text]:await loadImage(param.text)
    if(!cacheImgs[param.text]){
        cacheImgs[param.text] = _text
    }
    ctx.drawImage(_text, 0, 0, _text.width, _text.height, 0, 1020, _text.width, _text.height);
    const _qr_canvas = cacheImgs[param.qrcode]?cacheImgs[param.qrcode]:await qrcode(param)
    if(!cacheImgs[param.qrcode]){
        cacheImgs[param.qrcode] = _qr_canvas
    }
    ctx.drawImage(_qr_canvas, 0, 0, _qr_canvas.width, _qr_canvas.height, 404, 1150, _qr_canvas.width, _qr_canvas.height);
    ctx.fillStyle = "white";
    ctx.font = "40px Arial";
    ctx.textAlign = "center"
    ctx.textBaseline = 'middle';
    ctx.fillText(param.s_num, 509, 56);
    ctx.fillStyle = "#00175a";
    ctx.font = "bold 62px Arial";
    ctx.textAlign = "center"
    ctx.textBaseline = 'middle';
    ctx.fillText(param.blessing, 509, 950);
    return canvas.toDataURL('image/webp')
}

async function prizePoster(param){
    const canvas = document.createElement('canvas');
    canvas.width = param.with
    canvas.height = param.height
    const ctx = canvas.getContext('2d')
    const _bg = cacheImgs[param.bg]?cacheImgs[param.bg]:await loadImage(param.bg)
    if(!cacheImgs[param.bg]){
        cacheImgs[param.bg] = _bg
    }
    ctx.drawImage(_bg, 0, 0);
    const _title = cacheImgs[param.title]?cacheImgs[param.title]:await loadImage(param.title)
    if(!cacheImgs[param.title]){
        cacheImgs[param.title] = _title
    }
    ctx.drawImage(_title, 0, 0, _title.width, _title.height, 68, 1175, _title.width, _title.height);
    // const _cake = cacheImgs[param.cake]?cacheImgs[param.cake]:await loadImage(param.cake)
    // if(!cacheImgs[param.cake]){
    //     cacheImgs[param.cake] = _cake
    // }
    // ctx.drawImage(_cake, 0, 0, _cake.width, _cake.height, 482, 1100, 508, 463);
    const _text = cacheImgs[param.text]?cacheImgs[param.text]:await loadImage(param.text)
    if(!cacheImgs[param.text]){
        cacheImgs[param.text] = _text
    }
    ctx.drawImage(_text, 0, 0, _text.width, _text.height, 301, 945, _text.width, _text.height);
    const _prizePic = cacheImgs[param.prizePic]?cacheImgs[param.prizePic]:await loadImage(param.prizePic)
    if(!cacheImgs[param.prizePic]){
        cacheImgs[param.prizePic] = _prizePic
    }
    ctx.drawImage(_prizePic, 0, 0, _prizePic.width, _prizePic.height, 0, 178, _prizePic.width, _prizePic.height);
    const _qr_canvas = cacheImgs[param.qrcode]?cacheImgs[param.qrcode]:await qrcode(param)
    if(!cacheImgs[param.qrcode]){
        cacheImgs[param.qrcode] = _qr_canvas
    }
    ctx.drawImage(_qr_canvas, 0, 0, _qr_canvas.width, _qr_canvas.height, 654, 1157, _qr_canvas.width, _qr_canvas.height);
    ctx.fillStyle = "white";
    ctx.font = "40px Arial";
    ctx.textAlign = "center"
    ctx.textBaseline = 'middle';
    ctx.fillText(param.s_num, 509, 56);
    return canvas.toDataURL('image/webp')
}

// 检测敏感词
function detection(str) {
    let _b = {
        result:true,
        word:''
    }
    for(let item of sensitive){
        if(str.indexOf(item)!==-1){
            _b = {
                result:false,
                word:item
            }
            return _b
        }
    }
    return _b
}

// 对象转url参数
function objectToUrlParams(obj) {
    const params = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            // 确保值不是undefined，否则URL会包含类似key=undefined的参数
            if (value !== undefined) {
                params.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
            }
        }
    }
    return params.join('&');
}

const getTextInfo = (value) => {
    if(value>=LEVEL3){
        return {
            t:LEVEL4/10000,
            s:'四'
        }
    }else if(value>=LEVEL2){
        return {
            t:LEVEL3/10000,
            s:'三'
        }
    }else if(value>=LEVEL1){
        return {
            t:LEVEL2/10000,
            s:'二'
        }
    }else{
        return {
            t:LEVEL1/10000,
            s:'一'
        }
    }
}

const getCompleteStats = (value,grade) => {
    let isComplete = 0
    switch(grade){
        case 1:
         isComplete = value>=LEVEL1?1:0;
        break;
        case 2:
            isComplete = value>=LEVEL2?1:0;
        break;
        case 3:
            isComplete = value>=LEVEL3?1:0;
        break;
        case 4:
            isComplete = value>=LEVEL4?1:0;
        break;
        default:
            break;
        
    }
    return isComplete
}

const getUrlAllParams = () => {
    const params = new URLSearchParams(window.location.search);
    const allParams = Object.fromEntries(params);
    console.log(allParams)
    return allParams;
}

const getUrlParam = (key) => {
    const params = new URLSearchParams(window.location.search);
    const value = params.get(key)
    console.log(value)
    return value;
}

// 验证手机号
const validatePhoneNumber = (phoneNumber) => {
    const regex = /^1[3456789]\d{9}$/;
    return regex.test(phoneNumber);
  }

// 获取url上渠道
const getChannelCode = () => {
    return changeClient((window.AMEX_ALLPARAMS && window.AMEX_ALLPARAMS.client)?window.AMEX_ALLPARAMS.client:'')
}

// 渠道修改
const changeClient = (str) => {
    let _str = ''
    switch(str){
        case 'EC_WECHAT_MP': 
        _str = 'WECHAT'
        break;
        case 'EC_ALIPAY_MP': 
        _str = 'ALIPAY'
        break;
        default: 
        break;
    }
    if(!_str){
        _str = localStorage.getItem('userAgent')?localStorage.getItem('userAgent'):''
    }
    if(!_str && navigator.userAgent.toLowerCase().indexOf('miniprogram') !== -1){
        _str= 'WECHAT'
    }
    if(!_str && navigator.userAgent.indexOf('AliApp') > -1){
        _str= 'ALIPAY'
    }
    if(!_str){
        _str= 'WECHAT'
    }
    return _str
}
const Util = {
    loadImage:loadImage,
    poster:poster,
    prizePoster:prizePoster,
    detection:detection,
    objectToUrlParams:objectToUrlParams,
    getTextInfo:getTextInfo,
    getCompleteStats:getCompleteStats,
    getUrlAllParams:getUrlAllParams,
    getUrlParam:getUrlParam,
    validatePhoneNumber:validatePhoneNumber,
    getChannelCode:getChannelCode,
    changeClient:changeClient
}
export default Util;