let _doMain = ''
if(window.location.hostname==='localhost:3000'){
    _doMain = 'amex.mrm-mccann.cn'
}else{
    _doMain = window.location.hostname
}
const Config = {
    bindCardUrl:'/pages/user/cards/firstStep/index',
    doMain:`https://${_doMain}/`,
    origin:`https://${_doMain}`,
    ossDoMain:'https://aecdn.z3z2.com'
}
export default Config