import Blessing from '../blessing'
import styles from './index.module.css';
function BlessingBox({children}) {
    return (
      <div className={styles.blessing_box}>
       <Blessing type={1} initialPosition={[422,0]} zIndex="10">祝你生日快乐</Blessing>
       <Blessing type={2} initialPosition={[1036,30]} zIndex="9">美国运通生日快乐</Blessing>
       <Blessing type={3} initialPosition={[60,50]} zIndex="2">廿廿相伴</Blessing>
       <Blessing type={4} initialPosition={[803,77]} zIndex="8">生日快乐</Blessing>
       <Blessing type={5} initialPosition={[524,126]} zIndex="7">廿廿相伴</Blessing>
       <Blessing type={6} initialPosition={[188,143]} zIndex="6">美国运通 廿廿相伴</Blessing>
       <Blessing type={7} initialPosition={[1,169]} zIndex="2">廿廿相伴</Blessing>
       <Blessing type={8} initialPosition={[1094,179]} zIndex="4">廿廿相伴</Blessing>
       <Blessing type={9} initialPosition={[874,198]} zIndex="9">美运20年</Blessing>
       <Blessing type={10} initialPosition={[645,237]} zIndex="8">美运20年</Blessing>
       <Blessing type={11} initialPosition={[260,251]} zIndex="6">美国运通生日快乐</Blessing>
       <Blessing type={12} initialPosition={[124,295]} zIndex="7">美运20年</Blessing>
       <Blessing type={13} initialPosition={[807,307]} zIndex="10">美国运通生日快乐</Blessing>
       <Blessing type={14} initialPosition={[471,327]} zIndex="9">祝你生日快乐</Blessing>
       <Blessing type={15} initialPosition={[264,392]} zIndex="8">美国运通 廿廿相伴</Blessing>
       <Blessing type={16} initialPosition={[16,415]} zIndex="7">生日快乐</Blessing>
       <Blessing type={17} initialPosition={[1072,425]} zIndex="9">生日快乐</Blessing>
       <Blessing type={18} initialPosition={[689,431]} zIndex="4">美国运通生日快乐</Blessing>
       
       <Blessing type={1} initialPosition={[422+1290,0]} zIndex="10">祝你生日快乐</Blessing>
       <Blessing type={2} initialPosition={[1036+1290,30]} zIndex="9">美国运通生日快乐</Blessing>
       <Blessing type={3} initialPosition={[60+1290,50]} zIndex="2">廿廿相伴</Blessing>
       <Blessing type={4} initialPosition={[803+1290,77]} zIndex="8">生日快乐</Blessing>
       <Blessing type={5} initialPosition={[524+1290,126]} zIndex="7">廿廿相伴</Blessing>
       <Blessing type={6} initialPosition={[188+1290,143]} zIndex="6">美国运通 廿廿相伴</Blessing>
       <Blessing type={7} initialPosition={[1+1290,169]} zIndex="2">廿廿相伴</Blessing>
       <Blessing type={8} initialPosition={[1094+1290,179]} zIndex="4">廿廿相伴</Blessing>
       <Blessing type={9} initialPosition={[874+1290,198]} zIndex="9">美运20年</Blessing>
       <Blessing type={10} initialPosition={[645+1290,237]} zIndex="8">美运20年</Blessing>
       <Blessing type={11} initialPosition={[260+1290,251]} zIndex="6">美国运通生日快乐</Blessing>
       <Blessing type={12} initialPosition={[124+1290,295]} zIndex="7">美运20年</Blessing>
       <Blessing type={13} initialPosition={[807+1290,307]} zIndex="10">美国运通生日快乐</Blessing>
       <Blessing type={14} initialPosition={[471+1290,327]} zIndex="9">祝你生日快乐</Blessing>
       <Blessing type={15} initialPosition={[264+1290,392]} zIndex="8">美国运通 廿廿相伴</Blessing>
       <Blessing type={16} initialPosition={[16+1290,415]} zIndex="7">生日快乐</Blessing>
       <Blessing type={17} initialPosition={[1072+1290,425]} zIndex="9">生日快乐</Blessing>
       <Blessing type={18} initialPosition={[689+1290,431]} zIndex="4">美国运通生日快乐</Blessing>
      </div>
    );
  }
  
  export default BlessingBox;