import { useCallback, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";

import getGreetingCount from "../../request/getGreetingCount";
import NavCard from '../../components/nav_card';
import Tip from '../../components/tip';

import Config from '../../config';

function Home({logon}) {
    const [visibleTipDetail, setVisibleTipDetail] = useState(false)
    const [src] = useState('/images/tip.webp')
    let navigate = useNavigate();
    const onGoSend = useCallback(() => {
        navigate('/send');
    },[])
    const onShowCard = useCallback(() => {
        navigate('/card');
    },[])

    const onGreetingCount = useCallback(() => {
      getGreetingCount().then(res=>{
       if(res>=370000){
        setVisibleTipDetail(true)
       }
      }).catch(err=>{
        console.log(err)
      })
    },[])

    const onHideTipDetail = useCallback(() => {
      setVisibleTipDetail(false)
      onShowCard()
    },[onShowCard])

    useEffect(()=>{
      if(logon===true){
        onGreetingCount()
      }
    },[logon,onGreetingCount])

    let _count = 0
    const onDebug = useCallback(() => {
      _count++;
      if(localStorage.getItem('_debug')==='1'){
        localStorage.setItem('_debug','0')
      }
      if(_count===19){
        localStorage.setItem('_debug','1')
      }
    },[_count])
    return (
      <>
      <div className="Home page_container">
      <div className="h_container">
        <img className="h_slogan" src={`${Config.ossDoMain}/images/slogan.webp`} alt="" onClick={onDebug}/>
        <div className="h88"></div>
        <img className="h_pic_1" src={`${Config.ossDoMain}/images/h_pic_1.webp`} alt=""/>
        <div className="h24"></div>
        <img className="h_pic_2" src={`${Config.ossDoMain}/images/h_pic_2.webp`} alt=""/>
        <div className="h94"></div>
        <div className="btn_start" onClick={onGoSend}>
          <img className="t_start" src={`${Config.ossDoMain}/images/btn_start.webp`} alt="送出生日祝福"/>
        </div>
      </div>

      <NavCard onClick={onShowCard}></NavCard>
      
      </div>
      <Tip visible={visibleTipDetail} src={src} onClick={onHideTipDetail}></Tip>
      </>
    )
}
    export default Home;