import {useEffect, useState} from 'react';
import Config from '../../config';
import styles from './index.module.css';
import Util from '../../utils'
function Stage({current,onClick}) {
    const [checkedList, setCheckedList] = useState([false, false, false, false])
    useEffect(()=>{
        let _arr = [false, false, false, false]
        if(Util.getCompleteStats(current,4)){
            _arr = [true, true, true, true]
        }else if(Util.getCompleteStats(current,3)){
            _arr = [true, true, true, false]
        }else if(Util.getCompleteStats(current,2)){
            _arr = [true, true, false, false]
        }else if(Util.getCompleteStats(current,1)){
            _arr = [true, false, false, false]
        }else{
            _arr = [false, false, false, false]
        }
        setCheckedList(_arr)
    },[current])
    return (
    <>
    <div className={styles.stage}>
        {checkedList.map((item,index) => 
        <div key={index} className={`${styles.item} ${item?styles.checked:''}`} onClick={()=>{onClick(index)}}>
            <img className={styles.img} src={`${Config.ossDoMain}/images/prize_item_${index+1}.webp`} alt=""/>
        </div>
        )}
    </div>
    </>
    );
  }
  
  export default Stage;
  