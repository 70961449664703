import { useEffect, useState} from 'react'
import { BrowserRouter,Routes, Route } from "react-router-dom";

import Home from './pages/home';
import Send from './pages/send';
import Card from './pages/card';
import Task from './pages/task';

import Logo from './components/logo';
import NavRule from './components/nav_rule';
import Rule from './components/rule';
import Loading from './components/loading';


import Login from './request/login';
import bindCard from './request/bindCard';
// import { CSSTransition, TransitionGroup } from "react-transition-group";
import Util from './utils'; 
import './App.css';

const allParams = Util.getUrlAllParams()
if(allParams){
  window['AMEX_ALLPARAMS'] = allParams
}
console.log(allParams)
function App() {
  const [visibleRule, setVisibleRule] = useState(false)
  const [logon, setLogon] = useState(false)

    const onShowRule = () => {
      setVisibleRule(true)
    }
    const onHideRule = () => {
      setVisibleRule(false)
    }

    const errorFn = () => {
      try{
        // eslint-disable-next-line no-undef
        if(!!wx){
          // eslint-disable-next-line no-undef
          wx.miniProgram.reLaunch({
            url: '/pages/index/index'
          })
          // wx.miniProgram.navigateBack();
        }
        // eslint-disable-next-line no-undef
        if(!!my){
          // eslint-disable-next-line no-undef
          my.reLaunch({
            url: '/pages/index/index'
          })
          //my.navigateBack();
        }
      }catch(err){
        console.log(err)
      }
    }

    const onBindCard = () => {
      const params= {
        code:allParams.code,
        channelCode:Util.changeClient(allParams.client)
      }
      bindCard(params).then(res=>{
        console.log(res)
        if(res && res.length>0){
          window._alert(`系统自动识别你已绑定运通卡<br/>额外加赠${res.length}张抽奖卡！`)
        }
      }).catch(err=>{
        window._alert(err, errorFn)
      })
    }

  const onLogin = () => {
    const params = {
      code:allParams.code,
      channelCode:Util.changeClient(allParams.client)
    }
    if(allParams.referrerUserId){
      params['shareUid'] = allParams.referrerUserId
    }
    console.log("请求params",params)
    Login(params).then(()=>{
      window['logon'] = 1
      setLogon(true)
      onBindCard()
    }).catch(err=>{
      window._alert('登录信息已失效<br/>请返回小程序重新登录',errorFn)
    })
  }
  useEffect(()=>{
    onLogin()
  },[])
  return (
    <div className="App">
    <Logo></Logo>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home logon={logon}/>} />
        <Route path="/index.html" element={<Home/>} />
        <Route path="/send" element={<Send/>} />
        <Route path="/card" element={<Card/>} />
        <Route path="/task" element={<Task/>} />
      </Routes>
    </BrowserRouter>
    <NavRule onClick={onShowRule}></NavRule>
    <Rule visible={visibleRule} onClick={onHideRule}></Rule>
    <Loading></Loading>
    </div>
  );
}

export default App;
