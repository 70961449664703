import Util from "../utils";
function Login(params) {
    const urlParams = Util.objectToUrlParams(params);
    const pStr = urlParams.length>0?`?${urlParams}`:''
    return new Promise(function(resolve,reject){
        fetch(`/login${pStr}`)
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if(data.isSuccess){
                resolve()
            }else{
                reject('请求失败') 
            }
        })
        .catch(error => reject(error));
    })
}
export default Login;
