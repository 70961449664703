import {useEffect, useState} from 'react';
import BtnMax from '../btn_max';
import CloseBtn from '../close_btn';
import Config from '../../config';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import styles from './index.module.css';
function MyCard({listData,onGoInfo, onGoSend, onClose}) {
    const [cardList, setCardList] = useState([])
    const [initialSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)

    const onImgLoaded = () => {
       setLoaded(true)
    }
    useEffect(()=>{
        setCardList(listData)
    },[listData])
    return (
    <div className={styles.card_wrap}>
        <div className={styles.mask}></div>
        {/* <div className={styles.c_box} onClick={onClose}><CloseBtn></CloseBtn></div> */}
        <div className={styles.swiper_wrap}>
        <Swiper
        slidesPerView={3} 
        spaceBetween={20}
        centeredSlides={true} 
        initialSlide={initialSlide}
        >
        {cardList.map((item,index) =>    
        <SwiperSlide key={index}>

            <div className={styles.imgbox}>
                {!loaded && <div className='loadingAn'></div>}
                <img src={item.src} alt="" onLoad={onImgLoaded}/>
            </div>
            {item.url && <img className={styles.url} src={item.url} alt=""/>}
            {item.status===0 && <img className={styles.noWin} src={`${Config.ossDoMain}/images/icon_noWin.png`} alt="未中奖标识"/>}
            <div className={styles.h44}></div>
            {/* {item.status===-1 && 
            <BtnMax onClick={onClose}>
                <img src="/images/t_sxcjq.png" alt="收下抽奖卡"/>
            </BtnMax>
            } */}
            {item.status===-1 && 
            <div className={styles.b_box} onClick={onClose}><CloseBtn></CloseBtn></div>
            }
            {item.status===0 && 
            <BtnMax onClick={onGoSend}>
                <img src={`${Config.ossDoMain}/images/t_sgdzf.webp`} alt="去送祝福得更多抽奖卡"/>
            </BtnMax>
            }
            {item.status===1 && 
            <BtnMax onClick={()=>{
                console.log("zheli",item.level)
                onGoInfo(item.level)}}>
                <img src={`${Config.ossDoMain}/images/t_qwtxsjxx.png`} alt="前往填写信息"/>
            </BtnMax>
            }
            {item.status===2 && 
            <BtnMax onClick={()=>{onClose('已留资')}}>
                <img src={`${Config.ossDoMain}/images/t_qwtxsjxx.png`} alt="前往填写信息"/>
            </BtnMax>
            }
        </SwiperSlide>
        )}
        </Swiper>
        </div>
    </div>
    );
  }
  
  export default MyCard;
  