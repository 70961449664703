import BtnMax from '../btn_max';
import Config from '../../config';
import styles from './index.module.css';
function Notice({visible,src,str,onClick}) {
    return (
        <>
        {visible &&
        <div className={styles.notice}>
            <div className={styles.mask}></div>
            <div className={styles.con}>
                <img className={styles.pic} src={Config.ossDoMain+src} alt=""/>
                <div className={styles.str}>{str}</div>
                <div className={styles.h77}></div>
                <BtnMax onClick={onClick}>
                    <img src={`${Config.ossDoMain}/images/t_ckwdcjq.png`} alt="查看我的抽奖卡"/>
                </BtnMax>
            </div>
        </div>
        }
        </>
    );
  }
  
  export default Notice;
  