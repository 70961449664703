const sensitive = [
  "习近平",
  "平近习",
  "习太子",
  "习明泽",
  "温家宝",
  "温加宝",
  "温加饱",
  "温jb",
  "胡温",
  "胡jt",
  "胡boss",
  "胡王八",
  "hujintao",
  "胡jintao",
  "胡j涛",
  "胡惊涛",
  "胡景涛",
  "胡紧掏",
  "湖紧掏",
  "胡紧套",
  "锦涛",
  "hjt",
  "胡派",
  "胡主席",
  "刘永清",
  "胡海峰",
  "胡海清",
  "江泽民",
  "民泽江",
  "江胡",
  "江哥",
  "江主席",
  "江书记",
  "择民",
  "则民",
  "茳泽民",
  "江蛤蟆",
  "江贼",
  "江猪",
  "江绵恒",
  "江绵康",
  "王冶坪",
  "江泽慧",
  "邓小平",
  "平小邓",
  "毛泽东",
  "猫泽东",
  "猫则东",
  "毛zd",
  "毛太祖",
  "毛相",
  "主席画像",
  "改革历程",
  "朱镕基",
  "朱容基",
  "朱镕鸡",
  "朱容鸡",
  "朱云来",
  "李月月鸟",
  "李小鹏",
  "李小琳",
  "华主席",
  "白春礼",
  "薄熙来",
  "薄一波",
  "蔡赴朝",
  "蔡武",
  "曹刚川",
  "常万全",
  "陈炳德",
  "陈德铭",
  "陈建国",
  "陈良宇",
  "陈绍基",
  "陈同海",
  "陈至立",
  "戴秉国",
  "董建华",
  "杜德印",
  "杜世成",
  "傅锐",
  "郭伯雄",
  "郭金龙",
  "贺国强",
  "胡春华",
  "耀邦",
  "华建敏",
  "黄华华",
  "黄丽满",
  "黄兴国",
  "回良玉",
  "贾庆林",
  "贾廷安",
  "靖志远",
  "李长春",
  "李春城",
  "李建国",
  "李克强",
  "李岚清",
  "李沛瑶",
  "李荣融",
  "李瑞环",
  "李铁映",
  "李先念",
  "李学举",
  "李源潮",
  "栗智",
  "梁光烈",
  "廖锡龙",
  "林树森",
  "林炎志",
  "林左鸣",
  "令计划",
  "柳斌杰",
  "刘奇葆",
  "刘少奇",
  "刘延东",
  "刘云山",
  "刘志军",
  "龙新民",
  "路甬祥",
  "罗箭",
  "吕祖善",
  "马飚",
  "马恺",
  "孟建柱",
  "欧广源",
  "强卫",
  "沈跃跃",
  "宋平顺",
  "粟戎生",
  "苏树林",
  "孙家正",
  "铁凝",
  "屠光绍",
  "王东明",
  "汪东兴",
  "王鸿举",
  "王沪宁",
  "王乐泉",
  "王洛林",
  "王岐山",
  "王胜俊",
  "王太华",
  "王学军",
  "王兆国",
  "王振华",
  "吴邦国",
  "吴定富",
  "吴官正",
  "无官正",
  "吴胜利",
  "吴仪",
  "奚国华",
  "习仲勋",
  "徐才厚",
  "许其亮",
  "徐绍史",
  "杨洁篪",
  "叶剑英",
  "由喜贵",
  "于幼军",
  "俞正声",
  "袁纯清",
  "曾培炎",
  "曾庆红",
  "曾宪梓",
  "曾荫权",
  "张德江",
  "张定发",
  "张高丽",
  "张立昌",
  "张荣坤",
  "张志国",
  "赵洪祝",
  "周生贤",
  "周永康",
  "朱海仑",
  "中南海",
  "大陆当局",
  "中国当局",
  "北京当局",
  "共产党",
  "党产共",
  "共贪党",
  "产党共",
  "公产党",
  "工产党",
  "共c党",
  "共x党",
  "共铲",
  "供铲党",
  "供铲谠",
  "供铲裆",
  "共残党",
  "共残主义",
  "中共",
  "中珙",
  "传教",
  "恶党",
  "邪党",
  "共产专制",
  "共产王朝",
  "裆中央",
  "土共",
  "土g",
  "共狗",
  "g匪",
  "共匪",
  "仇共",
  "专制",
  "政府",
  "症腐",
  "政腐",
  "挡中央",
  "档中央",
  "中央领导",
  "中国zf",
  "中央zf",
  "国wu院",
  "中华帝国",
  "大陆官方",
  "北京政权",
  "胡锦涛",
  "贺子珍",
  "李德生",
  "姚依林",
  "李干成",
  "黄镇",
  "刘瑞龙",
  "黄敬",
  "薄熙",
  "周小川",
  "周建南",
  "温云松",
  "徐明",
  "李鹏",
  "法轮功",
  "台毒",
  "李洪志",
  "新疆棉花",
  "新疆骚乱",
  "毒品",
  "大麻",
  "冰毒",
  "摇头丸",
  "自杀",
  "中国复兴党",
  "曾道人",
  "六合彩",
  "雷管",
  "炸药",
  "火药",
  "原子弹",
  "炸弹",
  "恐怖分子",
  "氢弹",
  "起爆器",
  "燃烧弹",
  "弹药",
  "核弹",
  "武器",
  "ATOM弹",
  "农药",
  "气枪",
  "子弹",
  "手枪",
  "M1911",
  "火枪",
  "麻醉",
  "军用",
  "军火",
  "猎枪",
  "针头",
  "针筒",
  "警察",
  "军狗",
  "钢珠枪",
  "迷魂药",
  "手槍",
  "弓弩",
  "麻醉箭",
  "军刀",
  "军刺",
  "弹簧刀",
  "警用",
  "假币",
  "死亡",
  "恶警",
  "屠杀",
  "独裁",
  "海洛因",
  "可卡因",
  "暴政",
  "大法弟子",
  "护法",
  "两岸关系",
  "民国",
  "高干",
  "疆独",
  "藏独",
  "中日",
  "公安",
  "工商税务",
  "公检法",
  "民运",
  "六四",
  "自焚",
  "腐败",
  "白皮书",
  "保钓",
  "达赖",
  "弹劾",
  "钓鱼岛",
  "高官",
  "国贼",
  "劳改",
  "劳教",
  "炼功",
  "两会",
  "流亡",
  "太子",
  "统战",
  "洗脑",
  "暴乱",
  "骚乱",
  "身份证",
  "贪污",
  "gcd",
  "gongchandang",
  "阿共",
  "共一产一党",
  "供产",
  "共惨",
  "共产主义的幽灵",
  "拱铲",
  "老共",
  "中gong",
  "gc党",
  "贡挡",
  "gong党",
  "g产",
  "狗产蛋",
  "共残裆",
  "共产党腐败",
  "共产党专制",
  "共产党的报应",
  "共产党的末日",
  "communistparty",
  "政付",
  "正府",
  "政俯",
  "政f",
  "zhengfu",
  "政zhi",
  "gong和",
  "张曙",
  "买别墅",
  "玩女人",
  "贪20亿",
  "许宗衡",
  "贪财物",
  "李启红",
  "贪腐财富",
  "落马",
  "高官名单",
  "陈希同",
  "玩忽职守",
  "有期徒刑",
  "受贿罪",
  "滥用职权",
  "没收个人财产",
  "成克杰",
  "死刑",
  "程维高",
  "严重违纪",
  "开除党籍",
  "撤销职务",
  "刘方仁",
  "无期徒刑",
  "倪献策",
  "徇私舞弊",
  "梁湘",
  "以权谋私",
  "撤职。",
  "李嘉廷",
  "死刑缓期",
  "张国光",
  "韩桂芝",
  "黄瑶",
  "双规",
  "判处死刑",
  "剥夺政治权利终身",
  "没收个人全部财产",
  "石兆彬",
  "侯伍杰",
  "王昭耀",
  "剥夺政治权利",
  "沈图",
  "叛逃美国",
  "罗云光",
  "起诉",
  "张辛泰",
  "李效时",
  "边少斌",
  "徐鹏航",
  "违纪",
  "收受股票",
  "王乐毅",
  "李纪周",
  "郑光迪",
  "田凤山。",
  "邱晓华",
  "郑筱萸",
  "孙鹤龄",
  "蓝田造假案",
  "留党察看",
  "何洪达",
  "朱志刚",
  "杨汇泉",
  "官僚主义",
  "徐炳松",
  "托乎提沙比尔",
  "王宝森",
  "经济犯罪",
  "畏罪自杀。",
  "陈水文",
  "孟庆平",
  "胡长清",
  "朱川",
  "许运鸿",
  "丘广钟",
  "刘知炳",
  "丛福奎",
  "王怀忠",
  "巨额财产",
  "来源不明罪",
  "李达昌",
  "刘长贵",
  "王钟麓",
  "阿曼哈吉",
  "付晓光",
  "自动辞",
  "刘克田",
  "吕德彬",
  "刘维明",
  "双开",
  "刘志华",
  "孙瑜",
  "李堂堂",
  "韩福才 青海",
  "欧阳德 广东",
  "韦泽芳 海南",
  "铁英 北京",
  "辛业江 海南",
  "于飞 广东",
  "姜殿武 河北",
  "秦昌典 重庆",
  "范广举 黑龙江",
  "张凯广东",
  "王厚宏海南",
  "陈维席安徽",
  "王有杰河南",
  "王武龙江苏",
  "米凤君吉林",
  "宋勇辽宁",
  "张家盟浙江",
  "马烈孙宁夏",
  "黄纪诚北京",
  "常征贵州",
  "王式惠重庆",
  "周文吉",
  "王庆录广西",
  "潘广田山东",
  "朱作勇甘肃",
  "孙善武河南",
  "宋晨光江西",
  "梁春禄广西政协",
  "鲁家善 中国交通",
  "金德琴 中信",
  "李大强 神华",
  "吴文英 纺织",
  "查克明 华能",
  "朱小华光大",
  "高严 国家电力",
  "王雪冰",
  "林孔兴",
  "刘金宝",
  "张恩照",
  "康日新",
  "王益",
  "张春江",
  "洪清源",
  "平义杰",
  "李恩潮",
  "孙小虹",
  "陈忠",
  "慕绥新",
  "田凤岐",
  "麦崇楷",
  "柴王群",
  "吴振汉",
  "张秋阳",
  "徐衍东",
  "徐发 黑龙江",
  "张宗海",
  "丁鑫发",
  "徐国健",
  "李宝金",
  "单平",
  "段义和",
  "荆福生",
  "陈少勇",
  "黄松有",
  "皮黔生",
  "王华元",
  "王守业",
  "刘连昆",
  "孙晋美",
  "邵松高",
  "肖怀枢",
  "刘广智 空军",
  "姬胜德 总参",
  "廖伯年 北京",
  "爱女人",
  "爱液",
  "按摩棒",
  "拔出来",
  "爆草",
  "包二奶",
  "暴干",
  "暴奸",
  "暴乳",
  "爆乳",
  "暴淫",
  "屄",
  "被插",
  "被操",
  "被干",
  "逼奸",
  "仓井空",
  "插暴",
  "操逼",
  "操黑",
  "操烂",
  "肏你",
  "肏死",
  "操死",
  "操我",
  "厕奴",
  "插比",
  "插b",
  "插逼",
  "插进",
  "插你",
  "插我",
  "插阴",
  "潮吹",
  "潮喷",
  "成人dv",
  "成人电影",
  "成人论坛",
  "成人小说",
  "成人电",
  "成人卡通",
  "成人聊",
  "成人片",
  "成人视",
  "成人图",
  "成人文",
  "成人小",
  "成人色情",
  "成人网站",
  "成人文学",
  "艳情小说",
  "成人游戏",
  "吃精",
  "赤裸",
  "抽插",
  "扌由插",
  "抽一插",
  "春药",
  "大波",
  "大力抽送",
  "大乳",
  "荡妇",
  "荡女",
  "盗撮",
  "多人轮",
  "发浪",
  "放尿",
  "肥逼",
  "粉穴",
  "封面女郎",
  "风月大陆",
  "干死你",
  "干穴",
  "肛交",
  "肛门",
  "龟头",
  "裹本",
  "国产av",
  "好嫩",
  "豪乳",
  "黑逼",
  "后庭",
  "后穴",
  "虎骑",
  "花花公子",
  "换妻俱乐部",
  "黄片",
  "几吧",
  "鸡吧",
  "鸡巴",
  "鸡奸",
  "寂寞男",
  "寂寞女",
  "妓女",
  "激情",
  "集体淫",
  "奸情",
  "叫床",
  "脚交",
  "金鳞岂是池中物",
  "金麟岂是池中物",
  "精液",
  "就去日",
  "巨屌",
  "菊花洞",
  "菊门",
  "巨奶",
  "巨乳",
  "菊穴",
  "开苞",
  "口爆",
  "口活",
  "口交",
  "口射",
  "口淫",
  "裤袜",
  "狂操",
  "狂插",
  "浪逼",
  "浪妇",
  "浪叫",
  "浪女",
  "狼友",
  "聊性",
  "流淫",
  "铃木麻",
  "凌辱",
  "漏乳",
  "露b",
  "乱交",
  "乱伦",
  "轮暴",
  "轮操",
  "轮奸",
  "裸陪",
  "买春",
  "美逼",
  "美少妇",
  "美乳",
  "美腿",
  "美穴",
  "美幼",
  "秘唇",
  "迷奸",
  "密穴",
  "蜜穴",
  "蜜液",
  "摸奶",
  "摸胸",
  "母奸",
  "奈美",
  "奶子",
  "男奴",
  "内射",
  "嫩逼",
  "嫩女",
  "嫩穴",
  "捏弄",
  "女优",
  "炮友",
  "砲友",
  "喷精",
  "屁眼",
  "品香堂",
  "前凸后翘",
  "强jian",
  "强暴",
  "强奸处女",
  "情趣用品",
  "情色",
  "拳交",
  "全裸",
  "群交",
  "惹火身材",
  "人妻",
  "人兽",
  "日逼",
  "日烂",
  "肉棒",
  "肉逼",
  "肉唇",
  "肉洞",
  "肉缝",
  "肉棍",
  "肉茎",
  "肉具",
  "揉乳",
  "肉穴",
  "肉欲",
  "乳爆",
  "乳房",
  "乳沟",
  "乳交",
  "乳头",
  "三级片",
  "骚逼",
  "骚比",
  "骚女",
  "骚水",
  "骚穴",
  "色逼",
  "色界",
  "色猫",
  "色盟",
  "色情网站",
  "色区",
  "色色",
  "色诱",
  "色欲",
  "色b",
  "少年阿宾",
  "少修正",
  "射爽",
  "射颜",
  "食精",
  "释欲",
  "兽奸",
  "兽交",
  "手淫",
  "兽欲",
  "熟妇",
  "熟母",
  "熟女",
  "爽片",
  "爽死我了",
  "双臀",
  "死逼",
  "丝袜",
  "丝诱",
  "松岛枫",
  "酥痒",
  "汤加丽",
  "套弄",
  "体奸",
  "体位",
  "舔脚",
  "舔阴",
  "调教",
  "偷欢",
  "偷拍",
  "推油",
  "脱内裤",
  "文做",
  "我就色",
  "无码",
  "舞女",
  "无修正",
  "吸精",
  "夏川纯",
  "相奸",
  "小逼",
  "校鸡",
  "小穴",
  "小xue",
  "写真",
  "性感妖娆",
  "性感诱惑",
  "性虎",
  "性饥渴",
  "性技巧",
  "性交",
  "性奴",
  "性虐",
  "性息",
  "性欲",
  "胸推",
  "穴口",
  "学生妹",
  "穴图",
  "亚情",
  "颜射",
  "阳具",
  "杨思敏",
  "要射了",
  "夜勤病栋",
  "一本道",
  "一夜欢",
  "一夜情",
  "一ye情",
  "阴部",
  "淫虫",
  "阴唇",
  "淫荡",
  "阴道",
  "淫电影",
  "阴阜",
  "淫妇",
  "淫河",
  "阴核",
  "阴户",
  "淫贱",
  "淫叫",
  "淫教师",
  "阴茎",
  "阴精",
  "淫浪",
  "淫媚",
  "淫糜",
  "淫魔",
  "淫母",
  "淫女",
  "淫虐",
  "淫妻",
  "淫情",
  "淫色",
  "淫声浪语",
  "淫兽学园",
  "淫书",
  "淫术炼金士",
  "淫水",
  "淫娃",
  "淫威",
  "淫亵",
  "淫样",
  "淫液",
  "淫照",
  "阴b",
  "应召",
  "幼交",
  "幼男",
  "幼女",
  "欲火",
  "欲女",
  "玉女心经",
  "玉蒲团",
  "玉乳",
  "欲仙欲死",
  "玉穴",
  "援交",
  "原味内衣",
  "援助交际",
  "张筱雨",
  "招鸡",
  "招妓",
  "中年美妇",
  "抓胸",
  "自拍",
  "自慰",
  "作爱",
  "18禁",
  "99bb",
  "a4u",
  "a4y",
  "adult",
  "amateur",
  "anal",
  "a片",
  "fuck",
  "gay片",
  "g点",
  "g片",
  "hardcore",
  "h动画",
  "h动漫",
  "incest",
  "porn",
  "secom",
  "sexinsex",
  "sm女王",
  "xiao77",
  "xing伴侣",
  "tokyohot",
  "yin荡",
  "贱人",
  "装b",
  "大sb",
  "傻逼",
  "傻b",
  "煞逼",
  "煞笔",
  "刹笔",
  "傻比",
  "沙比",
  "欠干",
  "婊子养的",
  "我日你",
  "我操",
  "我草",
  "卧艹",
  "卧槽",
  "爆你菊",
  "艹你",
  "cao你",
  "你他妈",
  "真他妈",
  "别他吗",
  "草你吗",
  "草你丫",
  "操你妈",
  "擦你妈",
  "操你娘",
  "操他妈",
  "日你妈",
  "干你妈",
  "干你娘",
  "娘西皮",
  "狗操",
  "狗草",
  "狗杂种",
  "狗日的",
  "操你祖宗",
  "操你全家",
  "操你大爷",
  "妈逼",
  "你麻痹",
  "麻痹的",
  "妈了个逼",
  "马勒",
  "狗娘养",
  "贱比",
  "贱b",
  "下贱",
  "死全家",
  "全家死光",
  "全家不得好死",
  "全家死绝",
  "白痴",
  "无耻",
  "sb",
  "杀b",
  "你吗b",
  "你妈的",
  "婊子",
  "贱货",
  "人渣",
  "混蛋",
  "媚外",
  "和弦",
  "兼职",
  "限量",
  "铃声",
  "性伴侣",
  "男公关",
  "火辣",
  "精子",
  "射精",
  "诱奸",
  "强奸",
  "做爱",
  "性爱",
  "发生关系",
  "按摩",
  "快感",
  "处男",
  "猛男",
  "少妇",
  "屌",
  "屁股",
  "下体",
  "内裤",
  "浑圆",
  "咪咪",
  "发情",
  "刺激",
  "白嫩",
  "粉嫩",
  "兽性",
  "风骚",
  "呻吟",
  "sm",
  "阉割",
  "高潮",
  "裸露",
  "不穿",
  "一丝不挂",
  "脱光",
  "干你",
  "干死",
  "我干",
  "裙中性运动",
  "乱奸",
  "乱伦类",
  "乱伦小",
  "伦理大",
  "伦理电影",
  "伦理毛",
  "伦理片",
  "裸聊",
  "裸聊网",
  "裸体写真",
  "裸舞视",
  "裸照",
  "美女裸体",
  "美女写真",
  "美女上门",
  "美艳少妇",
  "妹按摩",
  "妹上门",
  "迷幻药",
  "迷幻藥",
  "迷昏口",
  "迷昏药",
  "迷昏藥",
  "迷魂香",
  "迷魂藥",
  "迷奸粉",
  "迷奸药",
  "迷情粉",
  "迷情水",
  "迷情药",
  "迷药",
  "迷藥",
  "谜奸药",
  "骚妇",
  "骚货",
  "骚浪",
  "骚嘴",
  "色电影",
  "色妹妹",
  "色情表演",
  "色情电影",
  "色情服务",
  "色情图片",
  "色情小说",
  "色情影片",
  "色情片",
  "色视频",
  "色小说",
  "性服务",
  "性福情",
  "性感少",
  "性伙伴",
  "性交视频",
  "性交图片",
  "性奴集中营",
  "阴蒂",
  "阴间来电",
  "阴茎增大",
  "阴茎助勃",
  "阴毛",
  "陰唇",
  "陰道",
  "陰戶",
  "淫荡美女",
  "淫荡视频",
  "淫荡照片",
  "淫乱",
  "淫靡",
  "淫魔舞",
  "淫情女",
  "淫肉",
  "淫騷妹",
  "淫兽",
  "淫兽学",
  "淫穴",
  "morphine",
  "乖乖粉",
  "narcotic",
  "麻醉药",
  "精神药品",
  "腐败中国",
  "三个呆婊",
  "你办事我放心",
  "社会主义灭亡",
  "打倒中国",
  "打倒共产党",
  "打倒共产主义",
  "打倒胡锦涛",
  "打倒江泽民",
  "打倒江主席",
  "打倒李鹏",
  "打倒罗干",
  "打倒温家宝",
  "打倒中共",
  "打倒朱镕",
  "抵制共产党",
  "抵制共产主义",
  "抵制胡锦涛",
  "抵制江泽民",
  "抵制江主席",
  "抵制李鹏",
  "抵制罗干",
  "抵制温家宝",
  "抵制中共",
  "抵制朱镕基",
  "灭亡中国",
  "亡党亡国",
  "粉碎四人帮",
  "激流中国",
  "特供",
  "特贡",
  "特共",
  "zf大楼",
  "殃视",
  "贪污腐败",
  "强制拆除",
  "形式主义",
  "政治风波",
  "太子党",
  "上海帮",
  "北京帮",
  "清华帮",
  "红色贵族",
  "权贵集团",
  "河蟹社会",
  "喝血社会",
  "九风",
  "9风",
  "十七大",
  "十7大",
  "17da",
  "九学",
  "9学",
  "四风",
  "4风",
  "南街村",
  "最淫官员",
  "警匪",
  "官匪",
  "独夫民贼",
  "官商勾结",
  "城管暴力执法",
  "强制捐款",
  "毒豺",
  "一党执政",
  "一党专制",
  "一党专政",
  "专制政权",
  "宪法法院",
  "胡平",
  "苏晓康",
  "贺卫方",
  "谭作人",
  "焦国标",
  "万润南",
  "张志新",
  "辛灝年",
  "高勤荣",
  "王炳章",
  "高智晟",
  "司马璐",
  "刘晓竹",
  "刘宾雁",
  "魏京生",
  "寻找林昭的灵魂",
  "别梦成灰",
  "谁是新中国",
  "讨伐中宣部",
  "异议人士",
  "民运人士",
  "启蒙派",
  "选国家主席",
  "民一主",
  "min主",
  "民竹",
  "民珠",
  "民猪",
  "chinesedemocracy",
  "大赦国际",
  "国际特赦",
  "da选",
  "投公",
  "公头",
  "宪政",
  "平反",
  "党章",
  "维权",
  "昝爱宗",
  "宪章",
  "08宪",
  "08xz",
  "抿主",
  "敏主",
  "人拳",
  "人木又",
  "人quan",
  "renquan",
  "中国人权",
  "中国新民党",
  "群体事件",
  "群体性事件",
  "上中央",
  "去中央",
  "讨说法",
  "请愿",
  "请命",
  "公开信",
  "联名上书",
  "万人大签名",
  "万人骚动",
  "截访",
  "上访",
  "shangfang",
  "信访",
  "访民",
  "集合",
  "集会",
  "组织集体",
  "静坐",
  "静zuo",
  "jing坐",
  "示威",
  "示wei",
  "游行",
  "you行",
  "油行",
  "游xing",
  "youxing",
  "官逼民反",
  "反party",
  "反共",
  "抗议",
  "亢议",
  "抵制",
  "低制",
  "底制",
  "di制",
  "抵zhi",
  "dizhi",
  "boycott",
  "血书",
  "焚烧中国国旗",
  "baoluan",
  "流血冲突",
  "出现暴动",
  "发生暴动",
  "引起暴动",
  "baodong",
  "灭共",
  "杀毙",
  "罢工",
  "霸工",
  "罢考",
  "罢餐",
  "霸餐",
  "罢参",
  "罢饭",
  "罢吃",
  "罢食",
  "罢课",
  "罢ke",
  "霸课",
  "ba课",
  "罢教",
  "罢学",
  "罢运",
  "网特",
  "网评员",
  "网络评论员",
  "五毛党",
  "五毛们",
  "5毛党",
  "戒严",
  "jieyan",
  "jie严",
  "戒yan",
  "8的平方事件",
  "知道64",
  "八九年",
  "贰拾年",
  "2o年",
  "20和谐年",
  "贰拾周年",
  "六河蟹四",
  "六百度四",
  "六和谐四",
  "陆四",
  "陆肆",
  "198964",
  "5月35",
  "89年春夏之交",
  "64惨案",
  "64时期",
  "64运动",
  "4事件",
  "四事件",
  "北京风波",
  "学潮",
  "学chao",
  "xuechao",
  "学百度潮",
  "门安天",
  "天按门",
  "坦克压大学生",
  "民主女神",
  "历史的伤口",
  "高自联",
  "北高联",
  "血洗京城",
  "四二六社论",
  "王丹",
  "柴玲",
  "沈彤",
  "封从德",
  "王超华",
  "王维林",
  "吾尔开希",
  "吾尔开西",
  "侯德健",
  "阎明复",
  "方励之",
  "蒋捷连",
  "丁子霖",
  "辛灏年",
  "蒋彦永",
  "严家其",
  "陈一咨",
  "中华局域网",
  "党的喉舌",
  "互联网审查",
  "当局严密封锁",
  "新闻封锁",
  "封锁消息",
  "爱国者同盟",
  "关闭所有论坛",
  "网络封锁",
  "金盾工程",
  "gfw",
  "无界浏览",
  "无界网络",
  "自由门",
  "何清涟",
  "中国的陷阱",
  "汪兆钧",
  "记者无疆界",
  "境外媒体",
  "维基百科",
  "纽约时报",
  "bbc中文网",
  "华盛顿邮报",
  "世界日报",
  "东森新闻网",
  "东森电视",
  "星岛日报",
  "wikipedia",
  "youtube",
  "googleblogger",
  "美国广播公司",
  "英国金融时报",
  "自由亚洲",
  "自由时报",
  "中国时报",
  "反分裂",
  "威胁论",
  "左翼联盟",
  "保钓组织",
  "主权",
  "弓单",
  "火乍",
  "木仓",
  "石肖",
  "核蛋",
  "步qiang",
  "bao炸",
  "爆zha",
  "baozha",
  "zha药",
  "zha弹",
  "炸dan",
  "炸yao",
  "zhadan",
  "zhayao",
  "hmtd",
  "三硝基甲苯",
  "六氟化铀",
  "炸药配方",
  "弹药配方",
  "炸弹配方",
  "皮箱炸弹",
  "火药配方",
  "人体炸弹",
  "人肉炸弹",
  "解放军",
  "兵力部署",
  "军转",
  "军事社",
  "8341部队",
  "第21集团军",
  "七大军区",
  "7大军区",
  "北京军区",
  "沈阳军区",
  "济南军区",
  "成都军区",
  "广州军区",
  "南京军区",
  "兰州军区",
  "颜色革命",
  "规模冲突",
  "塔利班",
  "基地组织",
  "恐怖份子",
  "三股势力",
  "印尼屠华",
  "印尼事件",
  "蒋公纪念歌",
  "马英九",
  "mayingjiu",
  "李天羽",
  "苏贞昌",
  "林文漪",
  "陈水扁",
  "陈s扁",
  "陈随便",
  "阿扁",
  "a扁",
  "告全国同胞书",
  "台百度湾",
  "台完",
  "台wan",
  "taiwan",
  "台弯",
  "湾台",
  "台湾国",
  "台湾共和国",
  "台军",
  "台独",
  "台du",
  "taidu",
  "twdl",
  "一中一台",
  "打台湾",
  "两岸战争",
  "攻占台湾",
  "支持台湾",
  "进攻台湾",
  "占领台湾",
  "统一台湾",
  "收复台湾",
  "登陆台湾",
  "解放台湾",
  "解放tw",
  "解决台湾",
  "光复民国",
  "台湾独立",
  "台湾问题",
  "台海问题",
  "台海危机",
  "台海统一",
  "台海大战",
  "台海战争",
  "台海局势",
  "入联",
  "入耳关",
  "中华联邦",
  "国民党",
  "x民党",
  "民进党",
  "青天白日",
  "闹独立",
  "duli",
  "fenlie",
  "日本万岁",
  "小泽一郎",
  "劣等民族",
  "汉人",
  "汉维",
  "维汉",
  "维吾",
  "吾尔",
  "热比娅",
  "伊力哈木",
  "东突厥斯坦解放组织",
  "东突解放组织",
  "蒙古分裂分子",
  "列确",
  "阿旺晋美",
  "藏人",
  "臧人",
  "zang人",
  "藏民",
  "藏m",
  "赖达",
  "dalai",
  "哒赖",
  "dl喇嘛",
  "丹增嘉措",
  "打砸抢",
  "西独",
  "葬独",
  "臧独",
  "藏毒",
  "藏du",
  "zangdu",
  "支持zd",
  "藏暴乱",
  "藏青会",
  "雪山狮子旗",
  "拉萨",
  "啦萨",
  "啦沙",
  "啦撒",
  "拉sa",
  "lasa",
  "la萨",
  "西藏",
  "藏西",
  "藏春阁",
  "藏獨",
  "藏独立",
  "藏妇会",
  "藏字石",
  "xizang",
  "xi藏",
  "x藏",
  "西z",
  "tibet",
  "希葬",
  "希藏",
  "硒藏",
  "稀藏",
  "西脏",
  "西奘",
  "西葬",
  "西臧",
  "援藏",
  "bjork",
  "王千源",
  "安拉",
  "回教",
  "回族",
  "回回",
  "回民",
  "穆斯林",
  "穆罕穆德",
  "穆罕默德",
  "默罕默德",
  "伊斯兰",
  "圣战组织",
  "清真",
  "清zhen",
  "qingzhen",
  "真主",
  "阿拉伯",
  "高丽棒子",
  "韩国狗",
  "满洲第三帝国",
  "满狗",
  "鞑子",
  "江丑闻",
  "江嫡系",
  "江毒",
  "江独裁",
  "江核心",
  "江黑心",
  "江胡内斗",
  "江祸心",
  "江家帮",
  "江派和胡派",
  "江派人马",
  "江泉集团",
  "江人马",
  "江三条腿",
  "江氏集团",
  "江氏家族",
  "江氏政治局",
  "江氏政治委员",
  "江梳头",
  "江太上",
  "江戏子",
  "江系人",
  "江系人马",
  "江宰民",
  "江贼民",
  "麻果丸",
  "麻将透",
  "麻醉弹",
  "麻醉狗",
  "麻醉枪",
  "麻醉槍",
  "麻醉藥",
  "台湾版假币",
  "台湾应该独立",
  "台湾有权独立",
  "天灭中共",
  "中共帮凶",
  "中共保命",
  "中共裁",
  "中共党文化",
  "中共腐败",
  "中共的血旗",
  "中共的罪恶",
  "中共帝国",
  "中共独裁",
  "中共封锁",
  "中共封网",
  "中共黑",
  "中共黑帮",
  "中共解体",
  "中共近期权力斗争",
  "中共恐惧",
  "中共权力斗争",
  "中共任用",
  "中共退党",
  "中共洗脑",
  "中共邪教",
  "中共政治游戏",
  "中共邪毒素",
  "福音会",
  "中国教徒",
  "统一教",
  "观音法门",
  "清海无上师",
  "盘古",
  "志洪李",
  "李宏志",
  "轮功",
  "法轮",
  "轮法功",
  "三去车仑",
  "氵去车仑",
  "发论工",
  "法x功",
  "法o功",
  "法0功",
  "法一轮一功",
  "轮子功",
  "车仑工力",
  "法lun",
  "fa轮",
  "法lg",
  "flg",
  "fl功",
  "falungong",
  "大纪元",
  "dajiyuan",
  "明慧网",
  "明慧周报",
  "正见网",
  "新唐人",
  "伪火",
  "退党",
  "tuidang",
  "退dang",
  "超越红墙",
  "自fen",
  "真善忍",
  "九评",
  "9评",
  "9ping",
  "九ping",
  "jiuping",
  "集体自杀",
  "自sha",
  "zi杀",
  "suicide",
  "titor",
  "逢8必灾",
  "逢八必灾",
  "逢9必乱",
  "逢九必乱",
  "朱瑟里诺",
  "根达亚文明",
  "诺查丹玛斯",
  "人类灭亡进程表",
  "按照马雅历法",
  "推背图",
  "推bei图",
  "济世灵文",
  "诸世纪",
  "电狗",
  "电话定位器",
  "电话拦截器",
  "电话窃听",
  "电话监",
  "电话交友",
  "电话追杀系统",
  "电击枪",
  "电鸡",
  "电警棒",
  "枪出售",
  "枪的制",
  "枪货到",
  "枪决女犯",
  "枪模",
  "枪手",
  "枪销售",
  "枪械制",
  "枪子弹",
  "售步枪",
  "售纯度",
  "售单管",
  "售弹簧刀",
  "售防身",
  "售狗子",
  "售虎头",
  "售火药",
  "售假币",
  "售健卫",
  "售军用",
  "售猎枪",
  "售氯胺",
  "售麻醉",
  "售枪支",
  "售热武",
  "售三棱",
  "售手枪",
  "售五四",
  "售一元硬",
  "售子弹",
  "售左轮",
  "亚砷（酸）酐",
  "亚砷酸钾",
  "亚砷酸钠",
  "亚硒酸",
  "亚硒酸二钠",
  "亚硒酸镁",
  "亚硒酸钠",
  "亚硒酸氢钠",
  "亚硝酸乙酯",
  "亚硝酰乙氧",
  "氧化二丁基锡",
  "氧化汞",
  "氧化铊",
  "氧化亚铊",
  "氧氯化磷",
  "原装弹",
  "原子弹方法",
  "原子弹清单",
  "安眠酮",
  "代血浆",
  "普萘洛尔",
  "呋塞米",
  "西布曲明",
  "testosterone",
  "胰岛素样生长因子",
  "促红细胞生成素",
  "地西泮",
  "尼可刹米",
  "甲睾酮",
  "adrenaline",
  "erythropoietin",
  "地奈德",
  "莫达非尼",
  "氯噻嗪",
  "苯巴比妥",
  "促性腺激素",
  "泼尼松",
  "麻黄草",
  "雄烯二醇",
  "地塞米松",
  "tamoxifen",
  "strychnine",
  "androst",
  "新型毒品",
  "杜冷丁",
  "兴奋剂",
  "mdma",
  "海luo因",
  "heroin",
  "diamorphine",
  "diacetylmorphine",
  "鸦片",
  "阿芙蓉",
  "咖啡因",
  "cocain",
  "三唑仑",
  "美沙酮",
  "麻古",
  "k粉",
  "凯他敏",
  "ketamine",
  "苯丙胺",
  "cannabis",
  "爱他死",
  "氯胺酮",
  "benzodiazepines",
  "甲基安非他明",
  "吗啡",
  "安非他命",
];

export default sensitive;
