import styles from './index.module.css';
function BtnMid({style,onClick,children}) {
    return (
      <div className={styles.btn_mid} style={style} onClick={onClick}>
        <div className={styles.btn_wrap}>
        {children}
        </div>
      </div>
    );
  }
  
  export default BtnMid;