import Config from "../../config";
function BtnMini({className,disabled=false,onClick,children}) {
    const backgroundStyle = {
        backgroundImage: disabled?`url(${Config.ossDoMain}/images/t_btn.webp)`:`url(${Config.ossDoMain}/images/t_btn.webp)`,
        backgroundSize: 'contain',
        width: '234px',
        height: '79px',
    };
    return (
      <button className={`${className} btn_mini`} style={backgroundStyle} disabled={disabled} onClick={onClick}>{children}</button>
    );
  }
  
  export default BtnMini;