import {useState} from 'react';
import Config from '../../config';
import BtnMid from '../btn_mid';

import styles from './index.module.css';
// import Util from '../../utils';
function CardList({cardList, onShowCard, onShowPrizeResult}) {

    // const [cardList, setCardList] = useState([])
    const [initialSlide] = useState(0)

    const onShowCurrentCard = (e) => {
        console.log("什么情况")
        console.log(e.target.dataset.index)
        onShowCard(e.target.dataset.index)
    }
    return (
     <>
    <div className={styles.card_wrap}>
        {cardList.map((item,index) => 
        <div key={index} className={styles.card_item}>
            {(item.count>0||item.status===1) && <div className={styles.label}>{item.count}</div>}
            {item.count<=0 && item.status===0 && <img className={styles.img} src={`${Config.ossDoMain}/images/card_poster_0.webp`} alt=""/>}
            {(item.count>0 ||item.status===1) && <img className={styles.img} src={Config.ossDoMain+item.src} alt="" data-index={index} onClick={onShowCurrentCard}/>}
            <img className={styles.t_d} src={`${Config.ossDoMain}/images/t_d_${index+1}.webp`} alt="第几轮"/>
            <img src={Config.ossDoMain+item.text} style={{opacity:item.status===0?0.4:1}} alt=""/>
            <div className={styles.h24}></div>
            {item.status===0 && 
            <BtnMid style={{opacity:0.3}}>
                <img src={`${Config.ossDoMain}/images/t_wkj.png`} alt="未开奖"/>
            </BtnMid>
            }
            {item.status===1 && 
            <BtnMid onClick={()=>{onShowPrizeResult(item.level)}}>
                <img src={`${Config.ossDoMain}/images/t_ckzjjg.png`} alt="查看中奖结果"/>
            </BtnMid>
            }
        </div>
        )}
    </div>
    </>
    );
  }
  
  export default CardList;
  