import {useCallback, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import BtnMini from '../../components/btn_mini';
import TaskList from '../../components/task_list';
import NavCard from '../../components/nav_card';
import BlessingBox from '../../components/blessing_box';

import Share from '../../components/share';
import Util from '../../utils';
import Config from '../../config';

import getQrCode from "../../request/getQrCode";
import upload from "../../request/upload";
function Task() {

  const [visibleShare, setVisibleShare] = useState(false)
  const [lotteryNumber, setLotteryNumber] = useState('')
  const [blessing, setBlessing] = useState('')
  const [src, setSrc] = useState('')
  const [url, setUrl] = useState('')
  const [qrcode, setQrcode] = useState('')

  const navigate = useNavigate();
  const isMiniprogram = localStorage.getItem('miniprogram')
  const onGoSend = useCallback(() => {
    navigate(-1);
  },[])

  const onShowShare = () => {
    drawCard()
    setVisibleShare(true)
  }

  const onGoBind = useCallback(() => {
    const backUrl = encodeURIComponent(Config.doMain)
    const code = window.AMEX_ALLPARAMS.code
    console.log("跳转地址",`${Config.bindCardUrl}?sourceFlag=amex20anniversary&backUrl=${backUrl}&code=${code}`)
    // eslint-disable-next-line no-undef
    if(isMiniprogram==="1" && !!wx){
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({
          url:`${Config.bindCardUrl}?sourceFlag=amex20anniversary&backUrl=${backUrl}&code=${code}`
      });
    }
    // eslint-disable-next-line no-undef
    if(!!my){
      // eslint-disable-next-line no-undef
      my.navigateTo({
          url:`${Config.bindCardUrl}?sourceFlag=amex20anniversary&backUrl=${backUrl}&code=${code}`
      });
    }
  },[])

  const onShowCard = useCallback(() => {
    navigate('/card');
  },[])

  const onHideShare = useCallback(() => {
    setVisibleShare(false)
  },[])

  // 支付宝需上传换取url地址
  const onAlipayUpload = (data) => {
    const param = {
      imgData:data,
    }
    upload(param).then(res=>{
      console.log("upload:",res)
      const _url = Config.origin+res
      if(res){
        setUrl(_url)
        // 实在海报图缓存
        try{
          lotteryNumber && localStorage.setItem(lotteryNumber+'_url',_url)
        }catch(err){
          console.log(err)
        }
      }
    }).catch(err=>{
      console.log(err)
      window._alert(err)
    })
  }

  const drawCard = async () => {

    if(lotteryNumber && localStorage.getItem(lotteryNumber)){
      setSrc(localStorage.getItem(lotteryNumber))
      console.log("分享海报->缓存",lotteryNumber)
      console.log("loc",localStorage.getItem(lotteryNumber))
      if(lotteryNumber && localStorage.getItem(lotteryNumber+'_url')){
        setUrl(localStorage.getItem(lotteryNumber+'_url'))
      }
      return
    }
    if(src || url){
      console.log("分享海报->读内存")
      return
    }
    // 随机一个颜色的分享圈
    const random = Math.floor(Math.random()*5)+1
    console.log("random:",random,lotteryNumber,qrcode)
    let param = {
      with:1018,
      height: 1426,
      bg:`/images/p_bg.webp`,
      title:`/images/p_save_title_1.webp`,
      // cake:`/images/p_cake_${random}.png`,
      card:`/images/p_card_${random}.webp`,
      text:`/images/p_save_text_1.webp`,
      mask:`/images/p_mask.png`,
      qrcode:qrcode,
      s_num:lotteryNumber?`抽奖卡号：${lotteryNumber}`:'',
      blessing:blessing,
    }
    const _data = await Util.poster(param)
    if(!_data) return
    setSrc(_data)
    console.log("分享海报->重新生成")
    // 实在海报图缓存
    try{
      lotteryNumber && localStorage.setItem(lotteryNumber,_data)
    }catch(err){
      console.log(err)
    }
    // 支付宝需上传换取url地址
    if(Util.getChannelCode()==='ALIPAY'){
      onAlipayUpload(_data)
    }
  }

  let retryCount = 0
  const onQrCode = () => {
    const param = {
      channelCode: Util.getChannelCode()
    }
    const _url = localStorage.getItem(`${param.channelCode}_qrcode_url`)
    if(_url){
      setQrcode(_url)
      return
    }
    getQrCode(param).then(res=>{
      console.log("getQrCode:",res)
      setQrcode(res)
      try{
        localStorage.setItem(`${param.channelCode}_qrcode_url`,res)
      }catch(err){

      }
    }).catch(err=>{
      if(retryCount<2){
        retryCount++;
        onQrCode()
      }else{
        setQrcode(`/images/${param.channelCode}_qrcode.webp`)
      }
      console.log(err)
    })
  }


  const init = () => {
   const lotNo = Util.getUrlParam('lotNo')
   const blessing = decodeURIComponent(Util.getUrlParam('blessing'))
   console.log("抽奖卡号",lotNo)
   onQrCode()
   setBlessing(blessing)
   console.log("blessing",blessing)
   if(lotNo){
    setLotteryNumber(lotNo)
   }
  }

  useEffect(()=>{
    if(!qrcode || !lotteryNumber) return
    // 有抽奖卡号的，弹出一下抽奖卡
    onShowShare()
  },[lotteryNumber,qrcode])

  
  useEffect(()=>{
    init()
  },[])
  return (
    <>
    <div className="Task page_container">
      <div className="t_container">
        <img src={`${Config.ossDoMain}/images/s_title.webp`} alt=""/>
        <div className="tags_wall_wrap">
          <BlessingBox></BlessingBox>
        </div>
        <div className="h100"></div>
        <TaskList>
          <BtnMini className="btn_m_2" onClick={onGoSend}>
            <img src={`${Config.ossDoMain}/images/t_zlyc.png`} alt="再送一次"/>
          </BtnMini>
          <BtnMini className="btn_m_3" onClick={onShowShare}>
            <img src={`${Config.ossDoMain}/images/t_fx.png`} alt="分享"/>
          </BtnMini>
          <BtnMini className="btn_m_1" onClick={onGoBind}>
            <img src={`${Config.ossDoMain}/images/t_qwbk.png`} alt="前往绑卡"/>
          </BtnMini>
        </TaskList>
      </div>
    </div>
    <NavCard onClick={onShowCard}></NavCard>
    <Share visible={visibleShare} src={src} url={url} onClick={onHideShare}></Share>
    </>
  )
}
export default Task;