import Util from "../utils";
function getMyPrizeCardCount(params) {
    const urlParams = Util.objectToUrlParams(params);
    const pStr = urlParams.length>0?`?${urlParams}`:''
    return new Promise(function(resolve,reject){
        fetch(`/user/getMyPrizeCardCount${pStr}`)
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if(data.isSuccess){
                resolve(data.data)
            }else{
                reject(data.errMsg||'请求失败') 
            }
        })
        .catch(error => reject(error));
    })
}
export default getMyPrizeCardCount;
