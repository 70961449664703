import {useEffect, useState} from 'react';
import Config from '../../config';
import Util from '../../utils';
import styles from './index.module.css';
function Progress({current}) {
    const [checkedList, setCheckedList] = useState([false, false, false, false, false])
    const [width, setWidth] = useState(0)
    
    useEffect(()=>{
        let _arr = [false, false, false, false, false]
        if(Util.getCompleteStats(current,4)===1){
            _arr = [false, true, true, true, true]

            setWidth(100)
        }else if(Util.getCompleteStats(current,3)===1){
            _arr = [false, true, true, true, false]
            let _w = 75 + Math.floor(100*(current-200000)/170000)*0.25
            setWidth(_w)
            
        }else if(Util.getCompleteStats(current,2)===1){
            console.log("2")
            _arr = [false, true, true, false, false]
            let _w = 50 + Math.floor(100*(current-70000)/130000)*0.25
            setWidth(_w)
           
        }else if(Util.getCompleteStats(current,1)===1){
            console.log("1")
            _arr = [false, true, false, false, false]
            let _w = 25 + Math.floor(100*(current-30000)/40000)*0.25
            setWidth(_w)
        }else{
            
            let _w = 0 + Math.floor(100*(current)/30000)*0.25
            setWidth(_w)
            _arr = [false, false, false, false, false]
        }
        setCheckedList(_arr)

        // 长度计算


    },[current])
    return (
    <div className={styles.progress}>
        <div className={styles.inner} style={{width:width+'%'}}></div>
        <div className={styles.steps}>
            {checkedList.map((item,index) => 
            <div key={index} className={`${styles.item} ${item?styles.checked:''}`}>
                <img src={`${Config.ossDoMain}/images/icon_checked.png`} alt=""/>
            </div>
            )}
        </div>
    </div>
    );
  }
  
  export default Progress;
  