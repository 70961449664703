import Config from '../../config';
import styles from './index.module.css';
function TaskList({children}) {
    const backgroundStyle = {
        backgroundImage: `url(${Config.ossDoMain}/images/task.webp)`,
        backgroundSize: 'contain',
        width: '1101px',
        height: '910px',
    };
    return (
      <div className={styles.task_wrap} style={backgroundStyle}>{children}</div>
    );
  }
  
  export default TaskList;