import { useEffect, useState } from 'react'
import Config from '../../config';
import styles from './index.module.css';
function TextArea({defaultValue='',onSendMessage}) {

  const [textValue, setTextValue] = useState(defaultValue)
  const handleChange = (e) => {
    setTextValue(e.target.value)
  }
  const onSend = () => {
    if(!textValue){
      window._alert('请输入祝福语')
    }
    onSendMessage(textValue)
  }
  useEffect(()=>{
    setTextValue(defaultValue)
  },[defaultValue])
  return (
    <div className={styles.text_area}>
      <textarea className={styles.area} placeholder="输入你对美国运通的生日祝福" value={textValue} maxLength={16} onChange={handleChange}></textarea>
      <div className={styles.b_area}>
        <div className={styles.b_con}>
          <button className={styles.btn} onClick={onSend} disabled={!textValue}>
            <img src={`${Config.ossDoMain}/images/btn_big.png`} alt=""/>
            <img src={`${Config.ossDoMain}/images/t_szfdcjk.png`} alt=""/>
          </button>
          <img className={styles.desc} src={`${Config.ossDoMain}/images/t_min_desc.png`} alt=""/>
        </div>
      </div>
    </div>
  );
}

export default TextArea;

